<template>
  <div>
    <b-modal
      id="detailLaporan"
      ok-only
      centered
      size="xl"
      content-class="shadow"
      hide-footer
    >
    <template #modal-title> <div style="font-size:18px;; font-weight:600;">Detail Laporan </div></template>
      <div style="width:78%;">
        <b-form-row>
          <b-col>
            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Nama kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{dataLaporan.nama_kegiatan}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Jenis kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{dataLaporan.masterJenisKegiatan.nama_jenis_kegiatan}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Tempat kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{dataLaporan.tempat_kegiatan}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2"> 
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Tanggal kegiatan</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{ dataLaporan.tanggal_kegiatan | moment('DD MMMM YYYY')}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Sumber Dana</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <span>{{dataLaporan.masterSumberDana.nama_sumber_dana}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-start">
                <span style="font-weight: 600">keterangan</span>
              </b-col><b-col sm="10" class="d-flex justify-content-start align-items-start">
                <span class="pr-3">:</span>
                <span>{{ dataLaporan.keterangan}}</span>
              </b-col>
            </b-row>

            <b-row class="mb-2">
              <b-col sm="2" class="d-flex justify-content-start align-items-center">
                <span style="font-weight: 600">Unggah File</span>
              </b-col><b-col sm="8" class="d-flex justify-content-start align-items-center">
                <span class="pr-3">:</span>
                <b-button size="sm" variant="success"><v-icon style="color:white;" small>mdi-eye</v-icon>Lihat File Scan</b-button>
              </b-col>
            </b-row>

          </b-col>
        </b-form-row>
        
      </div>
      <div class="text-center">
           
          <b-button
            variant="primary"
            class="mt-16 text-center"
            @click="$bvModal.hide('detailLaporan')"
          >
            Tutup
          </b-button>
        </div>
    </b-modal>
    <!-- end modal section -->

    <b-row class="mb-5">
      <b-col>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          <b>Riwayat</b>
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          size="sm"
          id="black-btn"
          class="mr-3"
          @click="exportExcel()"
        >
          <span v-if="!loading">
            <v-icon
              style="color:white;"
              small
            >mdi-file-excel</v-icon>
            Ekspor Ke Excel
          </span>
          <b-spinner small v-else ></b-spinner>
        </b-button>
        <b-button
          size="sm"
          id="black-btn"
          @click="exportPDF()"
        >
          <v-icon
            style="color:white;"
            small
          >mdi-file-pdf</v-icon>
          Cetak PDF
        </b-button>
      </b-col>
    </b-row>
    <div>
      <div
        class="shadow"
        style="border-radius:15px;"
      >
      <div class="table-responsive">
                    <table class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-bordered
              ">
                      <thead>
                        <tr class="text-left">
                          <th style="width: 20px" class="text-capitalize">No.</th>
                <th style="min-width: 50px" class="text-capitalize">Nama Kegiatan</th>
                <th style="min-width: 50px" class="text-capitalize">Jenis Kegiatan</th>
                <th style="min-width: 50px" class="text-capitalize">Semester</th>
                <th style="min-width: 50px" class="text-capitalize">Tanggal Pelaporan</th>
                <th style="min-width: 50px" class="text-capitalize">Aksi</th>
                        </tr>
                      </thead>
                      <tbody style="background:#FAFAFA;">
                        <template v-for="(item,index) in riwayatList" >
                          <tr :key="index">
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        ">{{ index + 1}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        "> {{ item.nama_kegiatan }}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          text-left
                          d-block
                          font-size-lg
                        ">{{item.masterJenisKegiatan.nama_jenis_kegiatan}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                          text-left
                        ">{{$moment(item.tanggal_kegiatan).quarter() > 2 ? '2' : '1'}}</span>
                            </td>
                            <td>
                              <span class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          text-left
                          font-size-lg
                        ">{{ item.tanggal_pelaporan | moment("DD-MM-YYYY")}}</span>
                            </td>
                            <td>
                              <b-button variant="success" size="sm" style="padding:5px" @click="showDetail(item.id)">
                                >> Detail
                                <!-- <v-icon style="color:white;" small>mdi-chevron-right</v-icon>
                                <v-icon style="color:white;" small>mdi-chevron-right</v-icon> -->
                              </b-button> 
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
      </div>
    </div>
  </div>
</template>
<style>
#black-btn {
  background-color: black;
  color: white;
}

.tabs .card-header {
  background-color: #e5e5e5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}
.close{
  position: absolute;
  right: 30px;
}
.nav-item {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  text-align: center;
  /* margin-right:3.35rem; */
}

.nav-item a {
  display: flex;
  justify-content: center;
}

.nav.nav-pills .nav-item {
    margin-right: 10px;
    margin-left: 10px;
}
.modal-header {
  display: flex;
  justify-content: center !important;
  border:none;
}
.modal-body {
  padding-top: 0px;
}
</style>
<script>
import Swal from "sweetalert2";
export default {
  name: "Informasi",
  data() {
    return {
      riwayatList: [],
      selectedID: '',
      loading: false,
      quarter: null,
      dataLaporan: {
        keterangan: '',
        masterJenisKegiatan: {
          nama_jenis_kegiatan: null
        },
        masterSumberDana: {
          nama_sumber_dana: null
        },
        nama_kegiatan: null,
        status: null,
        tanggal_kegiatan: null,
        tanggal_pelaporan:null,
        tempat_kegiatan: null
      }
    }
  },
  mounted(){
    this.getPelaporanRiwayat()
  },
  methods:{
    getPelaporanRiwayat(){
      this.$store
        .dispatch("getPelaporanRiwayat", 1)
        .then((res) => {
          this.riwayatList = res.data.data
        })
    },
    showDetail(id){
      this.detailLaporan(id)
      this.$bvModal.show('detailLaporan')
    },
    detailLaporan(id){
      this.createMode = false
      this.selectedID = id
      this.$store
        .dispatch("detailLaporan", id)
        .then((response) => {
            this.dataLaporan = response.data
        })
    },
    exportExcel(){
      let context = this
      context.loading = true

        this.$store
        .dispatch("exportExcel")
        .then(async function(response){
            context.loading = false
            if(response.error){
            await Swal.fire(
              {
                title: 'Download File Gagal',
                text: response.message,
                icon: "Warning",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
          } else {
            window.open(response.data.meta.urlPath , "_blank");
            await Swal.fire(
              {
                title: 'Download File berhasil',
                text: "",
                icon: "success",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
          }
        })
        .catch(async function(err) {
          context.loading = false
          await Swal.fire(
              {
                title: err.message,
                text: "",
                icon: "warning",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
        })
    },
    exportPDF(){
      let context = this
      context.loading = true

        this.$store
        .dispatch("exportPDF")
        .then(async function(response){
          if(response.error){
            await Swal.fire(
              {
                title: 'Download File Gagal',
                text: response.message,
                icon: "Warning",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
          } else {
            context.loading = false
            window.open(response.data.meta.urlPath , "_blank");
            await Swal.fire(
              {
                title: 'Download File berhasil',
                text: "",
                icon: "success",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
          }
        })
        .catch(async function(err) {
          context.loading = false
          await Swal.fire(
              {
                title: err.message,
                text: "",
                icon: "warning",
                confirmButtonColor: "#063A69",
                confirmButtonText: "Tutup",
                cancelButtonText: false
              },
              function() {
                Swal.close();
              }
            );
        })
    },
  }
};
</script>

<style>
.table.table-head-bg thead tr,
.table.table-head-bg thead th {
  background-color: #333333;
}
.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: #ffffff !important;
  text-align: center;
}
.table.table-head-custom tbody tr,
.table.table-head-custom tbody td {
  text-align: center;
}
</style>
